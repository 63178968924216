<template>
  <div class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

    <div class=" orderPanel  absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-sm rounded bg-slate-200">
      <div class="flex border-b border-blue-400 p-2">
        <div class="ml-auto font-bold text-xl">Profile</div>
        <div v-on:click="close()" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </div>
      </div>
      <div class=" flex flex-col p-6 text-xs md:text-base">
        <div class=" mb-2 ">
          <span> username :  {{ username }}</span>
        </div>
        <div class=" mb-2 ">
          <span> Full Name :  {{ fullname }}</span>
        </div>
        <div class=" mb-2 ">
          <span> Email :  {{ email }}</span>
        </div>
        <div class=" mb-2 ">
          <span> Phone Number:  {{ phone }}</span>
        </div>
        <div class="mb-2  ">
          <span> Address: NDOLA ONLY </span>
        </div>

         <!-- <input class=" m-4" type="password" v-model="password" placeholder="Enter Password" /> -->
        <div class="flex mb-4">
          <button v-on:click="openEditInfo"
           class="bg-green-200 font-semibold cursor-pointer shadow p-2 mx-auto">Edit Info</button>
          </div>
         <button v-on:click="logOut"
         class="bg-gray-200 font-semibold cursor-pointer shadow p-2 mx-auto">LOG Out</button>
      </div>
    </div>
    

  
  </div>
</template>

<script>
// @ is an alias to /src

// import axios from "axios";
export default {
  name: 'LogIn',
  components: {
    // signUpPage: SignUpPage,
  },
  data () {
       return{
        username:'',
        fullname:'',
        email:'',
        phone:''
       }
      },
  methods:{
    openEditInfo(){
      this.$parent.showUpdateProfile(true);
      this.$parent.showProfile(false);
    },
   logOut(){
      
      
    localStorage.clear();
    this.$parent.userLoggedin = false;
    // alert("loged out")
    this.$parent.setMessageBox("Logged Out", "accepted")
    this.close();
    location.reload()
      
    },
    close(){
      this.$parent.showProfile(false);
    }
  },
  mounted(){
    let user = JSON.parse(localStorage.getItem('account')).user;
    if(user) {
      console.log(user[1])
      this.username = user.username;
      this.fullname = user.first_name +" "+ user.last_name;
      this.phone = user.phone_number;
      this.email = user.email;
    }
  }
  
}
</script>
