<template>
    <div class=" absolute top-0 z-10 bg-gray-100 h-screen w-screen px-6 md:px-20 flex mt-4">
      <div class=" w-48 h-60 m-auto rounded flex flex-col place-content-evenly">
        <div v-on:click="showOrder()" class=" bg-green-200 rounded-full text-center p-1 font-bold text-lg cursor-pointer shadow" > All Cover Orders </div>        
        <div v-on:click="showRequests()" class=" bg-green-200 rounded-full text-center p-1 font-bold text-lg cursor-pointer shadow" > All Phone Requests</div>
        <div v-on:click="showInternetSubs()" class=" bg-green-200 rounded-full text-center p-1 font-bold text-lg cursor-pointer shadow" > All Internet Subs </div>
        <div v-on:click="showCommunities()" class=" bg-green-200 rounded-full text-center p-1 font-bold text-lg cursor-pointer shadow" > All Communities</div>
        <div v-on:click="showUsers()" class=" bg-green-200 rounded-full text-center p-1 font-bold text-lg cursor-pointer shadow" > All Users</div>
        <div v-on:click="showCosts_Income()" class=" bg-green-200 rounded-full text-center p-1 mt-4 font-bold text-lg cursor-pointer shadow" > Show Costs/Income </div>
      </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  
  
  export default {
    name: 'DashboardView',
    components: {
     
    },
    data () {
       return{
        username:'',
        fullname:'',
        email:'',
        phone:''
       }
      },
  methods:{
    openEditInfo(){
      this.$parent.showUpdateProfile(true);
      this.$parent.showProfile(false);
    },
   logOut(){
      
      
    localStorage.clear();
    this.$parent.userLoggedin = false;
    // alert("loged out")
    this.$parent.setMessageBox("Logged Out", "accepted")
    this.close();
      
    },
    showOrder(){
      this.$parent.showAllOrders(true);
    },
    showInternetSubs(){
      this.$parent.showAllInternetSubs(true);
    },
    showCommunities(){
      this.$parent.showAllCommunities(true);
    },
    showRequests(){
      this.$parent.showAllRequests(true);
    },
    showUsers(){
      this.$parent.showAllUsers(true);
    },
    showCosts_Income(){
      this.$parent.showCostIncome(true);
    }
  },
  }
  </script>