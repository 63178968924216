<template>
  <div class=" absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 opacity-60"></div>
    
    <div class=" absolute m-auto text-white"> 
      <loadingFlask></loadingFlask>
      <div class=" text-center text-3xl font-bold">Loading...</div>
      <!-- Loading..... -->
    </div>
  
  </div>
</template>

<script>
// @ is an alias to /src

// import axios from "axios";
import LoadingFlask from "@/components/flaskLoading.vue";
export default {
  name: 'LoadingPage',
  components: {
    // signUpPage: SignUpPage,
    loadingFlask:LoadingFlask
  },
  data () {
       return{
        
       }
      },
  methods:{
   
  }
  
}
</script>
