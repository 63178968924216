<template>
    <div class=" mt-4 pb-4">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgba(241, 242, 243, 0); display: block;" width="197px" height="197px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <defs>
            <clipPath id="ldio-yhhjqjyelu-cp" clipPathUnits="userSpaceOnUse">
            <!-- this is for the water height -->
            <rect x="0" y="53" width="100" height="47"></rect>
            </clipPath>
            <pattern id="ldio-yhhjqjyelu-pattern" patternUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
            <rect x="0" y="0" width="100" height="100" fill="#a8dbef"></rect><circle cx="100" cy="0" r="3" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 126;0 -26" keyTimes="0;1" dur="3.3333333333333335s" begin="-0.8666666666666667s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="49" cy="0" r="2" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 137;0 -37" keyTimes="0;1" dur="3.3333333333333335s" begin="-3.2333333333333334s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="48" cy="0" r="2" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 144;0 -44" keyTimes="0;1" dur="3.3333333333333335s" begin="-1.0333333333333334s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="88" cy="0" r="2" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 103;0 -3" keyTimes="0;1" dur="3.3333333333333335s" begin="-2.5s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="7" cy="0" r="3" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 110;0 -10" keyTimes="0;1" dur="3.3333333333333335s" begin="-3.1333333333333333s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="27" cy="0" r="2" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 128;0 -28" keyTimes="0;1" dur="3.3333333333333335s" begin="-2.566666666666667s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="53" cy="0" r="3" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 107;0 -7" keyTimes="0;1" dur="3.3333333333333335s" begin="-2.933333333333333s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="88" cy="0" r="3" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 118;0 -18" keyTimes="0;1" dur="3.3333333333333335s" begin="-1.7666666666666666s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="32" cy="0" r="3" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 139;0 -39" keyTimes="0;1" dur="3.3333333333333335s" begin="-0.5666666666666667s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="23" cy="0" r="2" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 140;0 -40" keyTimes="0;1" dur="3.3333333333333335s" begin="-0.3333333333333333s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="79" cy="0" r="3" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 124;0 -24" keyTimes="0;1" dur="3.3333333333333335s" begin="-1.5666666666666667s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="35" cy="0" r="3" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 123;0 -23" keyTimes="0;1" dur="3.3333333333333335s" begin="-0.26666666666666666s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="23" cy="0" r="2" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 136;0 -36" keyTimes="0;1" dur="3.3333333333333335s" begin="-3.033333333333333s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="64" cy="0" r="2" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 138;0 -38" keyTimes="0;1" dur="3.3333333333333335s" begin="-0.26666666666666666s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="16" cy="0" r="3" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 134;0 -34" keyTimes="0;1" dur="3.3333333333333335s" begin="-3s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="32" cy="0" r="2" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 137;0 -37" keyTimes="0;1" dur="3.3333333333333335s" begin="-0.8666666666666667s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="73" cy="0" r="2" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 135;0 -35" keyTimes="0;1" dur="3.3333333333333335s" begin="-3.2333333333333334s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="2" cy="0" r="2" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 105;0 -5" keyTimes="0;1" dur="3.3333333333333335s" begin="-1.8s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="18" cy="0" r="3" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 119;0 -19" keyTimes="0;1" dur="3.3333333333333335s" begin="-1.1666666666666667s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="25" cy="0" r="3" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 121;0 -21" keyTimes="0;1" dur="3.3333333333333335s" begin="-2.933333333333333s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="74" cy="0" r="3" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 148;0 -48" keyTimes="0;1" dur="3.3333333333333335s" begin="-0.5666666666666667s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="83" cy="0" r="2" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 114;0 -14" keyTimes="0;1" dur="3.3333333333333335s" begin="-2.7333333333333334s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="85" cy="0" r="2" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 141;0 -41" keyTimes="0;1" dur="3.3333333333333335s" begin="-0.6666666666666666s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="89" cy="0" r="3" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 119;0 -19" keyTimes="0;1" dur="3.3333333333333335s" begin="-2.8666666666666667s" repeatCount="indefinite"></animateTransform>
            </circle><circle cx="73" cy="0" r="2" fill="#efefef">
            <animateTransform attributeName="transform" type="translate" values="0 120;0 -20" keyTimes="0;1" dur="3.3333333333333335s" begin="-1.3333333333333333s" repeatCount="indefinite"></animateTransform>
            </circle></pattern></defs>
            <path fill="url(#ldio-yhhjqjyelu-pattern)" clip-path="url(#ldio-yhhjqjyelu-cp)" d="M59,37.3V18.9c3-0.8,5.1-3.6,5.1-6.8C64.1,8.2,61,5,57.1,5H42.9c-3.9,0-7.1,3.2-7.1,7.1c0,3.2,2.2,6,5.1,6.8v18.4c-11.9,3.8-20.6,15-20.6,28.2C20.4,81.8,33.7,95,50,95s29.6-13.2,29.6-29.6C79.6,52.2,70.9,41.1,59,37.3z"></path>
            <path fill="none" stroke="#636669" stroke-width="3" d="M59,37.3V18.9c3-0.8,5.1-3.6,5.1-6.8C64.1,8.2,61,5,57.1,5H42.9c-3.9,0-7.1,3.2-7.1,7.1c0,3.2,2.2,6,5.1,6.8v18.4c-11.9,3.8-20.6,15-20.6,28.2C20.4,81.8,33.7,95,50,95s29.6-13.2,29.6-29.6C79.6,52.2,70.9,41.1,59,37.3z"></path>
        </svg>
    </div>
</template>

<script>
    
    export default {
        props:{
            index: Number,
            dataObject: Object
        },
      data () {
        return {
        text:'',
        size: null
        };
      },
      methods: {
        deleteObject(index){
           console.log("deleted:",index) 
           this.$parent.removeTextObject(index);
        },
        selectObject(index){
            console.log("selected:",index)
            this.$parent.selectText(this.index);
        },
        updateText(){

            this.$parent.updateText(this.index,this.text,this.size);
        }
      },
 
    };
</script>