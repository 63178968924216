<template>
  <div id="app">
     
    <router-view/>
    
    <signUpPage v-if="showSignUpPanel"></signUpPage>
    <logInPage v-if="showLoginPanel"></logInPage>
    <profilePage v-if="showProfilePanel"></profilePage>
    
    <adminDashboard v-if="adminPage"></adminDashboard>
    <allOrderPage v-if="showAllOrdersPanel"></allOrderPage>
    <allInternetSubsPage v-if="showInternetSubsPanel"></allInternetSubsPage>
    <allCommunitiesPage v-if="showAllCommunitiesPanel"></allCommunitiesPage>
    <allRequestsPage v-if="showAllRequestsPanel"></allRequestsPage>
    <allUsersPage v-if="showAllUsersPanel"></allUsersPage>
    <costIncomeViewPage v-if="showCostIncomePanel"></costIncomeViewPage>
    
    <!-- <placingOrderPage v-if="showPlaceOrderPanel"></placingOrderPage> -->
    <updateProfilePage v-if="showUpdateProfilePanel"></updateProfilePage>
    
    <loadingPage v-if="isLoading"></loadingPage>
    <messagePage v-if="showMessagePanel" v-bind:Message="messageBox.message" v-bind:MsgType="messageBox.msgType"></messagePage>
    <!-- <previewport  v-bind:Message="messageBox.message" v-bind:MsgType="messageBox.msgType"></previewport> -->

    <!-- <transition name="fade" mode="out-in"> -->
      
    <!-- </transition> -->
    <!-- <footerbar/> -->
  </div>
</template>

<script>
 
  // import PreviewPort from "@/views/OrderPreviewView.vue";
  
  import SignUpView from "@/views/User/SignUpView.vue";
  import LogInPage from "@/views/User/LogInView.vue";
  import ProfilePage from "@/views/User/ProfileView.vue";
  import UpdateProfilePage from "@/views/User/UpdateInfoView.vue";
  
  import AllOrderPage from "@/views/admin/AllOrderView.vue";
  import AllInternetSubsPage from "@/views/admin/AllInternetSubsView.vue";
  import AllCommunitiesPage from "@/views/admin/AllCommunitiesView.vue";
  import CostIncomeViewPage from "@/views/admin/CostIncomeView.vue";
  import AllRequestsPage from "@/views/admin/AllRequestView.vue";
  import AllUsersPage from "@/views/admin/AllUsersView.vue";
  import AdminDashboard from "@/views/admin/DashboardView.vue";
  
  import LoadingPage from "@/views/LoadingView.vue";
  import MessagePage from "@/views/MessageView.vue";
  
  // import Footerbar from "@/components/FooterBar.vue";
  export default {
    components: {
      
      // previewport: PreviewPort,
      
      signUpPage: SignUpView,
      logInPage: LogInPage,
      profilePage: ProfilePage,
      updateProfilePage: UpdateProfilePage,
      
      loadingPage: LoadingPage,
      messagePage: MessagePage,


      allOrderPage: AllOrderPage,
      allInternetSubsPage: AllInternetSubsPage,
      allCommunitiesPage: AllCommunitiesPage,
      costIncomeViewPage: CostIncomeViewPage,
      allRequestsPage: AllRequestsPage,
      allUsersPage: AllUsersPage,
      adminDashboard: AdminDashboard,

      
      // footerbar: Footerbar,
    },
    data () {
        return {
        userLoggedin:false,
        
        showProfilePanel: false,
        showUpdateProfilePanel: false,
        showLoginPanel: false,
        showSignUpPanel: false,
        
        isLoading: false,
        showMessagePanel: false,
       
        adminPage:false,
        showAllOrdersPanel: false,
        showInternetSubsPanel: false,
        showAllCommunitiesPanel: false,
        showAllRequestsPanel: false,
        showAllUsersPanel: false,
        showCostIncomePanel: false,
        // currentUser:[],
        
        messageBox:{
          message:"Test message",
          msgType:"accepted"
        }
        };
      },
      methods: {
        
        showProfile(show){
          this.showProfilePanel = show;
        },
        showLogin(show){          
          this.showLoginPanel = show;
        },
        showSignUp(show){
          this.showSignUpPanel = show;
        },


        showAllOrders(show){
          this.showAllOrdersPanel = show;
        },
        showAllInternetSubs(show){
          this.showInternetSubsPanel = show;
        },
        showAllCommunities(show){
          this.showAllCommunitiesPanel = show;
        },
        showAllRequests(show){
          this.showAllRequestsPanel = show;
        },
        showAllUsers(show){
          this.showAllUsersPanel = show;
        },
       
        showCostIncome(show){
          this.showCostIncomePanel = show;
        },
        showAdminDashboard(show){
          this.adminPage = show;
        },
        
        showUpdateProfile(show){
          this.showUpdateProfilePanel = show;
        },
        showLoading(show){
          console.log("loading...:", show)
          this.isLoading = show;
        },

        setMessageBox(message, type){
          this.messageBox.message = message;
          this.messageBox.msgType = type;
          this.showMessage(true);
        },

        showMessage(show){
          this.showMessagePanel = show;
        },

      },
    // mounted()
    created() {
      let user = localStorage.getItem('account');
      if(user) {
        this.userLoggedin = true;
        // alert("user is logged in")
      }
  
    }
  };
</script>


<style>
html,
body {
  width: 100vw;
  height: 100%;
  display: flex;
  overflow: hidden;
}
body {
  margin: 0px;
}
canvas {
  position: relative;

}
#app {
  height: 100%;
  width: 100vw;
  /* display: flex; */
  flex-direction: column;
  /* overflow: auto; */
}
</style>