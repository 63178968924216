<template>
  <div class=" absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>
    <div class=" absolute flex flex-col m-auto text-xl font-bold w-10/12 md:w-96 rounded bg-white p-6">
      
      <div v-if="MsgType == 'error'" class=" error self-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 text-red-500">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
        </svg>
      </div>

      <div v-else-if="MsgType == 'warning'" class=" warning self-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 text-yellow-300">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
        </svg>
      </div>

      <div v-else-if="MsgType == 'accepted'" class=" accepted self-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" text-green-400 w-12 h-12">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
      <div class=" text-center"> {{ Message }} </div>
      <button
              class=" mt-4 self-center bg-gray-200 border-gray-300 border font-medium text-gray-600 cursor-pointer shadow py-2 px-4 mx-auto"
              @click="close()"
            >
              OK
      </button>
    </div>
  
  </div>
</template>

<script>
// @ is an alias to /src

// import axios from "axios";
export default {
  name: 'MessagePage',
  components: {
    // signUpPage: SignUpPage,
  },
  props:{
      Message: String,
      MsgType: String
  },
  data () {
       return{
        
       }
      },
  methods:{
    close(){
      this.$parent.showMessage(false);
    }
  }
  
}
</script>
