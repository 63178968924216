<template>
  <div class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

    <div class=" orderPanel  absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-sm rounded bg-slate-200">
      <div class="flex border-b border-blue-400 p-2">
        <div class="ml-auto font-bold text-xl">Create Community</div>
        <div v-on:click="close()" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </div>
      </div>

      <div class=" md:h-auto" >
        
        <div class=" flex flex-col p-6">
          <div class=" m-2">Choose Community:</div>

          <select v-model="selectedCommunity">
            <option disabled value="">Please select Community</option>
            <option v-for="(data,i) in data" :key="i" :value="data"> {{ data.community_name }} </option>
            
          </select>
          
           <button 
            class=" my-3 bg-gray-200 font-bold text-gray-600 cursor-pointer shadow p-2 mx-auto" 
            v-on:click="updateUserCommunity()"> 
            Update
          </button>
          
        </div>
      </div>
    
    
    </div>

  
  </div>
</template>

<script>
// @ is an alias to /src

import axios from "axios";
export default {
  name: 'SingUpView',
  components: {
    // signUpPage: SignUpPage,
  },
  data () {
       return{
        communityname:'',
        country:'Zambia',
        province:'Copperbelt',
        city:'Ndola',
        area:'',
        locationcoordinates:'',
        coverage:'',
        selectedCommunity:null,
        selectedUser:[],
        data:[],
        errors: [],
        BackendUrl: "https://labsapi.packymstechnology.com"
       }
      },
  methods:{
    async loadAllCommunities(){
      this.$parent.showLoading(true)
      let dev = process.env.VUE_APP_DEVELOPMENT;    
    if (dev == "true") {
      console.log("is in dev mode")
      this.BackendUrl = "";
    }
         
      try {
        let result = await axios.get(this.BackendUrl + "/admin/communities", {
            headers:{
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('account')).access_token}`
          }
          });
          if(result.status == 200){
            // alert(" Order fetched")
            this.data = result.data
            // this.close();
  
          } else {
            alert("there was an error with placing Order")
          }
      } catch (error) {
        const code = error.response.status
        
          if(code == 401){
            this.close();
            this.$parent.setMessageBox("Please LogIn Again", "warning")
            this.$parent.showLogin(true);
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
      }
     
  
          
          this.$parent.showLoading(false)
    },

    async updateUserCommunity(){
      console.log(this.selectedCommunity)
      if(this.selectedCommunity && this.selectedCommunity.id != 0){
        this.$parent.showLoading(true)
        let dev = process.env.VUE_APP_DEVELOPMENT;    
        if (dev == "true") {
          console.log("is in dev mode")
          this.BackendUrl = "";
        }
        try {
          let result = await axios.put(this.BackendUrl + "/admin/update_user_community", {
          // username: JSON.parse(localStorage.getItem('account')).user.username,
          username:this.selectedUser.username,
          community_id:this.selectedCommunity.id,
          
        });

        console.log(result)

        if(result.status == 200){
          this.close();
          // localStorage.setItem('account', JSON.stringify(result.data))
          this.$parent.setMessageBox("Updated User Community.", "accepted")
          this.close()
        } else {
          alert("there was an error")
          
          
        }
        } catch (error) {
          const code = error.response.status
          if(code == 403){
            this.$parent.setMessageBox("Username or email already taken", "warning")
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
        }
      } else{
        this.$parent.setMessageBox("You need to set a Community.", "warning")
      }
        
        this.$parent.showLoading(false)
    },

    close(){
      this.$parent.showEditUserCommunityPanel(false);
    }

  },
  mounted(){
    let user = localStorage.getItem('account');
    if(user) {
      this.selectedUser = this.$parent.selectedUser
      console.log(this.selectedUser)
      this.loadAllCommunities()
    }
  }
  
}
</script>

<style scoped>

.panel{
  max-height: 80%;
}

</style>
