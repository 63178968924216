<template>
  <div class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

    <div class=" orderPanel  absolute flex flex-col m-auto w-10/12 md:w-4/5 rounded bg-slate-200">
      <div class="flex border-b border-blue-400 p-2">
        <div class="ml-auto font-bold text-xl">Update Order Status</div>
        <div v-on:click="close()" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </div>
      </div>
      
      
      <div class=" flex flex-col overflow-y-auto mt-2 w-full text-sm">

        <div> </div>
        <div> </div>


        <div  class=" w-full p-4">
          <div class="flex flex-col w-full h-full rounded bg-gray-100 p-4">
                       
            <div> Please Select Current Request Status</div>
            
          </div>
          <section class=" mt-4 flex">
            <div class=" m-auto"> <input type="radio" v-model="status" value="PENDING" >PENDING </div>
            <div class=" m-auto"> <input type="radio" v-model="status" value="PROCESSING" >PROCESSING </div>           
            <div class=" m-auto"> <input type="radio" v-model="status" value="READY" >READY </div>           
            
          </section>

          
        </div>

        <button v-if="status"
                class=" mt-4 mb-4 self-center bg-gray-200 border-gray-300 border font-medium text-gray-600 cursor-pointer shadow py-2 px-4 mx-auto"
                @click="UpdateRequests()"
              >
                Update Status
        </button>
      </div>
    
    </div>
    

  
  </div>
</template>

<script>
// @ is an alias to /src

import axios from "axios";
export default {
  name: 'OrderPage',
  components: {
    // signUpPage: SignUpPage,
  },
  props:{
    paymentInfo: Object
  },
  data () {
       return{
        status:'',
        data:[],
        pageNumber: 1,
        PageSize: 5,
        BackendUrl: "https://labsapi.packymstechnology.com"
       }
      },
  methods:{
    close(){
      this.$parent.showUpdateRequestStatus(false);
    },
    async UpdateRequests(){
      // this.$parent.showLoading(true)
      let dev = process.env.VUE_APP_DEVELOPMENT;    
    if (dev == "true") {
      console.log("is in dev mode")
      this.BackendUrl = "";
    }
         
    try {
          let result = await axios.put(this.BackendUrl + "/admin/update_request_status", {
            id:this.$parent.selectedRequest,
            request_status:this.status
          },{
            headers:{
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('account')).access_token}`
          }
          });
          if(result.status == 200){
            this.$parent.setMessageBox("Updated Request Status.", "accepted");
            this.$parent.updateAndClose(this.status);
  
  
          } else {
            // alert("there was an error with placing Order")
            this.$parent.setMessageBox("Something Went Wrong, please try again", "warning")
          }
        } catch (error) {
          const code = error.response.status
          
          if(code == 401){
            this.$parent.setMessageBox("Please LogIn Again", "warning")
            this.close();
            this.$parent.$parent.showLogin(true);
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
        }
        
        this.$parent.$parent.showLoading(false)
    }
  },
  mounted(){
    // let user = localStorage.getItem('account');
   
  }
  
}
</script>
<style scoped>
.orderPanel{
max-height: 85%;
}
</style>
