<template>
    <div class=" flex flex-col items-center mt-4 w-full h-full">
        
        <div class="piechart w-24 h-24" :style="{'background-image': 'conic-gradient(#f87171 0'+culculatePercentDegrees(costs,income)+'deg, #22c55e 0)'}"></div>
    </div>
</template>

<script>
    
    export default {
        props:{
            costs: Number,
            income: Number
        },
      data () {
        return {
        // costsDeg: 250,
        // income:80
        };
      },
      methods: {
        culculatePercentDegrees(costs,income){
            return (360*costs)/(costs+income)
        }
      },
 
    };
</script>

<style>
    .piechart {
        border-radius: 50%;
        
    }
</style>