import Vue from "vue";
import Vuex from "vuex";
// import ThreeBSP from "./threeCSG.es6"
import {
  Scene,
  // TrackballControls,
  OrbitControls,
  TransformControls,
  PerspectiveCamera,
  WebGLRenderer,
  Color,
  FogExp2,
  // CylinderBufferGeometry,
  MeshPhongMaterial,
  MeshStandardMaterial,
  Mesh,
  // SphereGeometry,
  // BoxGeometry,
  DirectionalLight,
  AmbientLight,
  LineBasicMaterial,
  Geometry,
  Vector3,
  Line,
  // MeshStandardMaterial,
  FontLoader,
  TextGeometry,
  // TextBufferGeometry,
  STLExporter,
  STLLoader,
  // BufferGeometryUtils,
  // BufferGeometryLoader

} from "three-full";

import * as THREE from 'three-full'

// import { 
//   SUBTRACTION, 
//   Brush, 
//   Evaluator 
// } from 'three-bvh-csg';

const ThreeBSP = require('three-js-csg')(THREE)

// const worker = new Worker(new URL('./worker.js', import.meta.url), { type: 'module' });
// const geometriesLoader = new THREE.BufferGeometryLoader();

// const raycaster = new THREE.Raycaster();
// const pointer = new THREE.Vector2();
// const onUpPosition = new THREE.Vector2();
// const onDownPosition = new THREE.Vector2();

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        width: 0,
        height: 0,
        camera: null,
        raycaster:null,
        pointer:null,
        controls: null,
        orbit:null,
        transformControls: null,
        transformControls2: null,
        scene: null,
        renderer: null,
        axisLines: [],
        TextObjects: [],
        TextObject: null,
        textMesh1: null,
        textGeo: null,
        fullMesh:null,
        firstLetter: true,
        text: 'PT Labs',
        textSize: 10,
        textRotation: 0,
        CurrentPhoneMeshPath: 'phones/iphones/iphone_case.stl',
        materials: null,
        materials2: null,
        whiteMaterials: null,
        blackMaterials: null,
        grayMaterials: null,
        currentColor:null,
        currentFont: undefined,
        exporter:null,
        currentPhoneMesh:null,
        AppliedMeshList:[],
        CurrentAppliedMeshIndex:null,
        CurrentAppliedTextIndex:null,
        IsMobilePhone: false,
        

        exportedmesh:null,

        INTERSECTED:null,
        selectedItem:null,
        selectedIndex:null,

        screenShotData: null,


        fontName: 'BlackOpsOne',
        fontWeight: 'Regular',
        
    },

    getters: {
      CAMERA_POSITION: state => {
        return state.camera ? state.camera.position : null;
      },
      MESH_TEXT: state =>{
        return state.text;
      },
      MESH_TEXT_SIZE: state =>{
        return state.textSize;
      },
      MESH_TEXT_ROTATION: state =>{
        return state.textRotation;
      },
      GET_TEXTS_OBJECTS: state =>{
        return state.TextObjects;
      },
      GET_TEXTS_OBJECT: state =>{
        return state.TextObject;
      },
      GET_CURRENT_OBJECT_INDEX: state =>{
        return state.CurrentAppliedTextIndex;
      },
      GET_SELECTED_OBJECT_INDEX: state =>{
        return state.selectedIndex;
      },
      GET_CURRENT_EXPORTED_MESH: state =>{
        return state.exportedmesh;
      },
      SHOW_REDO: state =>{
        if(state.AppliedMeshList.length != 0 && state.AppliedMeshList.length != state.CurrentAppliedMeshIndex+1 ){
          return true;
        } else{
          return false
        }
        
      },
      SHOW_UNDO: state =>{
        if(state.CurrentAppliedMeshIndex>0 || state.CurrentAppliedMeshIndex != null )
        {
          return true;
        } else{
          return false;
        }
      },
      GET_SCREENSHOT: state =>{
        return state.screenShotData
      }
      
    },

    mutations: {
    SET_VIEWPORT_SIZE(state, { width, height }) {
      state.width = width;
      state.height = height;
    },
    SET_IS_MOBILE_PHONE(state, isPhone){
      state.IsMobilePhone = isPhone;
    },

    INITIALIZE_RENDERER(state, el) {
        state.renderer = new WebGLRenderer({ antialias: true,  preserveDrawingBuffer: true});
        state.renderer.setPixelRatio(window.devicePixelRatio);
        state.renderer.setSize(state.width, state.height);
        el.appendChild(state.renderer.domElement);
      },

      INITIALIZE_CAMERA(state) {
        state.camera = new PerspectiveCamera(
          // 1. Field of View (degrees)
          60,
          // 2. Aspect ratio
          state.width / state.height,
          // 3. Near clipping plane
          1,
          // 4. Far clipping plane
          1000
        );
        state.camera.position.z = 200;
      },

      INITIALIZE_CONTROLS(state) {
        // state.controls = new TrackballControls(
        //   state.camera,
        //   state.renderer.domElement
        // );
        // state.controls.rotateSpeed = 1.0;
        // state.controls.zoomSpeed = 1.2;
        // state.controls.panSpeed = 0.8;
        // state.controls.noZoom = false;
        // state.controls.noPan = false;
        // state.controls.staticMoving = true;
        // state.controls.dynamicDampingFactor = 0.3;
        state.orbit = new OrbitControls( state.camera, state.renderer.domElement );
				state.orbit.update();
        

      },

      INITIALIZE_TRANSFORM_CONTROLS(state){
      
        // // state.renderer.domElement.style.touchAction = 'none'; // disable touch scroll
        // state.transformControls = new TransformControls( state.camera, state.renderer.domElement );
				// state.transformControls.addEventListener( 'change', () => {
        //   state.renderer.render(state.scene, state.camera);
        // });
				// state.transformControls.addEventListener( 'dragging-changed', function ( event ) {

				// 	state.orbit.enabled = ! event.value;

				// } );

        // state.transformControls.showZ = false
				// state.scene.add( state.transformControls );

				
        // document.addEventListener( 'pointerdown', function ( event ) {
        //   onDownPosition.x = event.clientX;
        //   onDownPosition.y = event.clientY;
        //   console.log("over Text")
        // } );
        // document.addEventListener( 'pointerup', function ( event ) {
        //   onUpPosition.x = event.clientX;
        //   onUpPosition.y = event.clientY;
        //   if ( onDownPosition.distanceTo( onUpPosition ) === 0 ) {
        //     state.transformControls.detach();
        //     state.renderer.render(state.scene, state.camera);
        //   }
        // });
        // document.addEventListener( 'pointermove', function ( event ) {
        //   pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        //   pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        //   raycaster.setFromCamera( pointer, state.camera );
        //   const intersects = raycaster.intersectObjects( state.TextObjects, false );
        //   if ( intersects.length > 0 ) {
        //     const object = intersects[ 0 ].object;
        //     if ( object !== state.transformControls.object ) {
        //       state.transformControls.attach( object );
        //     }
        //   }
        // });


        state.transformControls = new TransformControls( 
          state.camera, 
          state.renderer.domElement 
        );

        state.transformControls.addEventListener("change", () => {
          state.renderer.render(state.scene, state.camera);
        });

        state.transformControls.addEventListener( 'dragging-changed', (event)  => {
					state.orbit.enabled = ! event.value;
				} );

        // state.transformControls.size = 0.65;
        state.transformControls.setMode( 'rotate' );
        state.transformControls.showY = false;
        state.transformControls.showX = false;
        // state.transformControls.space = 'world';
        // state.transformControls.attach( state.textMesh1 );
        // state.scene.add( state.transformControls );

        state.transformControls2 = new TransformControls( 
          state.camera, 
          state.renderer.domElement 
        );

        state.transformControls2.addEventListener("change", () => {
          state.renderer.render(state.scene, state.camera);
        });
        state.transformControls2.addEventListener("click", () => {
          console.log("CLicked transform");
        });

        state.transformControls2.addEventListener( 'dragging-changed', (event)  => {
					state.orbit.enabled = ! event.value;
				} );
        state.transformControls2.size = 0.65;
        state.transformControls2.showZ = false;
        state.transformControls.space = 'world';
        // state.transformControls2.attach( state.textMesh1 );
        // state.scene.add( state.transformControls2 );


      },

      INITIALIZE_SCENE(state) {
        state.scene = new Scene();
        state.scene.background = new Color(0xcccccc);
        state.scene.fog = new FogExp2(0xcccccc, 0.002);

        state.exporter = new STLExporter()

        state.materials = new MeshPhongMaterial({
          color: 0xffffff,
          flatShading: true
          
          
        });
        state.whiteMaterials = new MeshStandardMaterial({
          color: 'white',
          flatShading: true,
          emissive: 0xb3b3b3,
          roughness: 0.6,
          metalness: 0
        });
        state.blackMaterials = new MeshStandardMaterial({
          color: 0x141414,
          flatShading: true,
          emissive: 0x000000,
          roughness: 0.6,
          metalness: 0
        });
        state.grayMaterials = new MeshPhongMaterial({
          color: 'gray',
          flatShading: true,
          emissive: 0x5e5e5e,
          roughness: 0.6,
          metalness: 0
        });
        state.materials2 = new THREE.MeshBasicMaterial({
          color:  0xe0e0ff,
          wireframe: true
        });


        // Setup Raycaster
        // state.raycaster = new THREE.Raycaster();
        // state.pointer = new THREE.Vector2();

        // function onPointerMove( event ) {

        //   // calculate pointer position in normalized device coordinates
        //   // (-1 to +1) for both components
        
        //   state.pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        //   state.pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
        
        // }

        // window.addEventListener( 'pointermove', onPointerMove );

        // let firstLetter = true;
        // let text = 'test';

        // let bevelEnabled = false;

        // // let font = undefined;


        // const height = 20,
        //   size = state.textSize,
        //   // hover = 0,
  
        //   curveSegments = 2,
  
        //   bevelThickness = 2,
        //   bevelSize = 1.5;
      

          const loader = new FontLoader();
    
            loader.load( 'fonts/' + state.fontName + '_' + state.fontWeight + '.json', function ( response ) {

              state.currentFont = response;
             
      
        state.fullMesh = state.currentPhoneMesh;

        console.log("Meshed parsedGeom")
        console.log(state.fullMesh)
        console.log("Mesh Before parse")
        console.log(state.currentPhoneMesh)

        



        state.scene.add(state.fullMesh);

        // const controls = new TransformControls(state.camera, state.renderer.domElement)
        // controls.addEventListener( 'change', () => {
        //   state.renderer.render(state.scene, state.camera);
        // } )
        // controls.addEventListener( 'dragging-changed', function ( event ) {

				// 	state.orbit.enabled = ! event.value;

				// } );
        // controls.attach(state.fullMesh)
        // state.scene.add(controls)

       
        // state.scene.add(state.textMesh1);

        // state.transformControls = new TransformControls( 
        //   state.camera, 
        //   state.renderer.domElement 
        // );

        // state.transformControls.addEventListener("change", () => {
        //   state.renderer.render(state.scene, state.camera);
        // });

        // state.transformControls.addEventListener( 'dragging-changed', (event)  => {
				// 	state.orbit.enabled = ! event.value;
				// } );

        // // state.transformControls.size = 0.65;
        // state.transformControls.setMode( 'rotate' );
        // state.transformControls.showY = false;
        // state.transformControls.showX = false;
        // // state.transformControls.space = 'world';
        // // state.transformControls.attach( state.textMesh1 );
        // // state.scene.add( state.transformControls );

        // state.transformControls2 = new TransformControls( 
        //   state.camera, 
        //   state.renderer.domElement 
        // );

        // state.transformControls2.addEventListener("change", () => {
        //   state.renderer.render(state.scene, state.camera);
        // });
        // state.transformControls2.addEventListener("click", () => {
        //   console.log("CLicked transform");
        // });

        // state.transformControls2.addEventListener( 'dragging-changed', (event)  => {
				// 	state.orbit.enabled = ! event.value;
				// } );
        // state.transformControls2.size = 0.65;
        // state.transformControls2.showZ = false;
        // state.transformControls.space = 'world';
        // // state.transformControls2.attach( state.textMesh1 );
        // // state.scene.add( state.transformControls2 );


        // state.textGeo = new TextGeometry( "text", {

        //   font: state.currentFont,

        //   size: 20,
        //   height: 20,
        //   curveSegments: 1,

        //   bevelThickness: 2,
        //   bevelSize: 1.5,
        //   bevelEnabled: false

        // } );
        // // console.log(index)
        // // state.textGeo.computeBoundingBox();

        // // const centerOffset = - 0.5 * ( state.textGeo.boundingBox.max.x - state.textGeo.boundingBox.min.x );
        // // const centerOffsetY = - 0.5 * ( state.textGeo.boundingBox.max.y - state.textGeo.boundingBox.min.y );

        // state.textMesh1 = new Mesh( state.textGeo, state.materials2 );
        // // state.textMesh1 = state.textGeo;
        // state.textMesh1.geometry.center()
        // // state.textMesh1.position = state.TextObjects[state.CurrentAppliedTextIndex].position;

        // state.scene.add(state.textMesh1);


        // const controls = new TransformControls(state.camera, state.renderer.domElement)

        // controls.size = 2
        // controls.showZ = false
        // controls.addEventListener( 'change', () => {
        //   state.renderer.render(state.scene, state.camera);
        // } )
        // controls.addEventListener( 'dragging-changed', function ( event ) {
        //   console.log("CLicked transform");
				// 	state.orbit.enabled = ! event.value;

				// } );
        // controls.addEventListener("click", function() {
        //   console.log("CLicked transform");
        // });
        // controls.attach(state.textMesh1)
        // state.scene.add(controls)
    
        } );
        
        
				

        console.log(state.currentFont)
          

        
        // state.scene.add(mesh2);
        // lights
        var lightA = new DirectionalLight(0xffffff);
        lightA.position.set(1, 1, 1);
        state.scene.add(lightA);
        var lightB = new DirectionalLight(0x002288);
        lightB.position.set(-1, -1, -1);
        state.scene.add(lightB);
        var lightC = new AmbientLight(0x222222);
        state.scene.add(lightC);
        // Axis Line 1
        var materialB = new LineBasicMaterial({ color: 0x0000ff });
        var geometryB = new Geometry();
        geometryB.vertices.push(new Vector3(0, 0, 0));
        geometryB.vertices.push(new Vector3(0, 1000, 0));
        var lineA = new Line(geometryB, materialB);
        state.axisLines.push(lineA);
        // Axis Line 2
        var materialC = new LineBasicMaterial({ color: 0x00ff00 });
        var geometryC = new Geometry();
        geometryC.vertices.push(new Vector3(0, 0, 0));
        geometryC.vertices.push(new Vector3(1000, 0, 0));
        var lineB = new Line(geometryC, materialC);
        state.axisLines.push(lineB);
        // Axis 3
        var materialD = new LineBasicMaterial({ color: 0xff0000 });
        var geometryD = new Geometry();
        geometryD.vertices.push(new Vector3(0, 0, 0));
        geometryD.vertices.push(new Vector3(0, 0, 1000));
        var lineC = new Line(geometryD, materialD);
        state.axisLines.push(lineC);
        state.scene.add(...state.axisLines);


        
      },

      SET_CAMERA_POSITION(state, { x, y, z }) {
        if (state.camera) {
          state.camera.position.set(x, y, z);
        }
      },
      SET_TEXT_POSITION(state, { type, value }) {
        console.log("type:")
        console.log(type)
        console.log("value")
        console.log(value)

        if (state.TextObjects[state.CurrentAppliedTextIndex]) {
          
          if(type == "R"){
            console.log("Rotating")
            state.TextObjects[state.CurrentAppliedTextIndex].rotation.z = state.TextObjects[state.CurrentAppliedTextIndex].rotation.z + Math.PI/180*value
          } else if(type =="X"){
            state.TextObjects[state.CurrentAppliedTextIndex].position.x = state.TextObjects[state.CurrentAppliedTextIndex].position.x + value;
          } else if(type =="Y"){
            state.TextObjects[state.CurrentAppliedTextIndex].position.y = state.TextObjects[state.CurrentAppliedTextIndex].position.y + value;
          }
        }
        state.renderer.render(state.scene, state.camera);
      },

      CACULATE_RAY(state){
        state.raycaster.setFromCamera(state.pointer,state.camera);
        // calculate objects intersecting the picking ray
        const intersects = state.raycaster.intersectObjects( state.scene.children );
        
        intersects[0].object.material.color.set(0xff0000);
      },

      SET_TEXT(state, {index,text,size,flipH}) {


        state.textGeo = new TextGeometry( text, {

          font: state.currentFont,

          size: size,
          height: 20,
          curveSegments: 1,

          bevelThickness: 2,
          bevelSize: 1.5,
          bevelEnabled: false

        } );
        console.log(index)
        // state.textGeo.computeBoundingBox();

        // const centerOffset = - 0.5 * ( state.textGeo.boundingBox.max.x - state.textGeo.boundingBox.min.x );
        // const centerOffsetY = - 0.5 * ( state.textGeo.boundingBox.max.y - state.textGeo.boundingBox.min.y );

        state.textMesh1 = new Mesh( state.textGeo, state.materials2 );
        // state.textMesh1 = state.textGeo;
        state.textMesh1.geometry.center()
        state.textMesh1.position = state.TextObjects[state.CurrentAppliedTextIndex].position;
        // state.textMesh1.position.y = 0;
        // state.textMesh1.position.z = 0;

        if(flipH == true){
          state.textMesh1.rotation.y = Math.PI/180*180;
        } else{
          state.textMesh1.rotation.y = 0;
        }
        

        state.textMesh1.rotation.z = state.TextObjects[state.CurrentAppliedTextIndex].rotation.z;
        // state.textMesh1.rotation.y = 0;
        // state.textMesh1.rotation.z = Math.PI/180*state.textRotation;

        state.transformControls.detach();
        state.transformControls2.detach();
        state.scene.remove(state.transformControls);
        state.scene.remove(state.transformControls2);

        state.scene.remove(state.TextObjects[state.CurrentAppliedTextIndex]);
        console.log("TEXT MESH BEFORE:")
        console.log(state.TextObjects[state.CurrentAppliedTextIndex].geometry.parameters.text)
        // state.TextObjects[index].geometry.parameters.text = text;
        // state.TextObjects[index].geometry.parameters.parameters.size = size;
        state.TextObjects[state.CurrentAppliedTextIndex] = state.textMesh1;

        console.log("TEXT MESH AFTER:")
        console.log(state.TextObjects[state.CurrentAppliedTextIndex].geometry.parameters.text);
        state.scene.add(state.TextObjects[state.CurrentAppliedTextIndex]);
        state.renderer.render(state.scene, state.camera);
        
      },
      SET_TEXT_SIZE(state, size) {
        // console.log("ASAHFGAHDGVDHGVADHGVDH")
          state.textSize = size;
          // state.renderer.render(state.scene, state.camera);
        
      },
      SET_TEXT_ROTATION(state, rotation) {

          state.textRotation = rotation;
          // state.renderer.render(state.scene, state.camera);
        
      },
      SET_SCREENSHOT_POSITION(state){
        if (state.camera) {
          state.camera.position.set(-126.36616396406318, -6.803244944147506, 121.03810773945146);
          state.camera.rotation.set(0.05289693586125997, -0.740796809626191, 0.035717016243077014);
          state.scene.remove(...state.axisLines);
          state.renderer.render(state.scene, state.camera);
          
        }
      },
      // REGENERATE_GEOMETRY(state){

       
      //   console.log("222")
        


      //   // state.textGeo.computeBoundingBox();
    
      //   // const centerOffset = - 0.5 * ( state.textGeo.boundingBox.max.x - state.textGeo.boundingBox.min.x );
      //   // const centerOffsetY = - 0.5 * ( state.textGeo.boundingBox.max.y - state.textGeo.boundingBox.min.y );

      //   // state.textMesh1 = new Mesh( state.textGeo, state.materials );
      //   // state.textMesh1.geometry.center();
      //   // state.textMesh1.position.x = 0;
      //   // state.textMesh1.position.y = 0;
      //   // state.textMesh1.position.z = 0;

      //   // state.textMesh1.rotation.x = 0;
      //   // state.textMesh1.rotation.y = 0;
      //   // state.textMesh1.rotation.z = Math.PI/180*state.textRotation;

      //   state.scene.remove(state.fullMesh)
      //   state.scene.remove(state.TextObjects[state.TextObjects[state.selectedIndex]])
      //   var temp = state.currentPhoneMesh

      //   state.TextObjects.forEach(text => {
      //     const sBSP = new ThreeBSP(text);
      //     const bBSP = new ThreeBSP(temp);
  
      //     const sub = bBSP.subtract(sBSP);
      //     temp = sub.toMesh();
      //   });
      //   state.fullMesh = temp
       

      //   // var bsp1 = new ThreeBSP(mesh1)
      //   // var bsp2 = new ThreeBSP(mesh2)
      //   // var bsp = bsp1.subtract(bsp2)
      //   // var mesh = bsp.toMesh(material)
      
        
      //   state.scene.add(state.fullMesh);
      //   state.renderer.render(state.scene, state.camera);

      //   return false;

      // },

      SET_FULL_MESH(state,mesh){
        state.fullMesh = mesh;
      },
      SET_COLOR(state,color){
        state.currentColor = color
        if(state.currentColor == 'black'){
          state.fullMesh.material = state.blackMaterials
        } else if(state.currentColor == 'white'){
          state.fullMesh.material = state.whiteMaterials
        } else if(state.currentColor == 'gray'){
          state.fullMesh.material = state.grayMaterials
        } else{
          state.fullMesh.material = state.materials
        }
        state.renderer.render(state.scene, state.camera);        
      },
      RESET_CAMERA_ROTATION(state) {
        if (state.camera) {
          state.camera.rotation.set(0, 0, 0);
          state.camera.quaternion.set(0, 0, 0, 1);
          state.camera.up.set(0, 1, 0);
          // state.controls.target.set(0, 0, 0);
        }
      },

      RESIZE(state, { width, height }) {
        state.width = width;
        state.height = height;
        state.camera.aspect = width / height;
        state.camera.updateProjectionMatrix();
        state.renderer.setSize(width, height);
        // state.controls.handleResize();
        state.renderer.render(state.scene, state.camera);
      },
      HIDE_AXIS_LINES(state) {
        state.scene.remove(...state.axisLines);
        state.renderer.render(state.scene, state.camera);
      },
      SHOW_AXIS_LINES(state) {
        state.scene.add(...state.axisLines);
        state.renderer.render(state.scene, state.camera);
      },
      HIDE_PYRAMIDS(state) {
        state.scene.remove(...state.pyramids);
        state.renderer.render(state.scene, state.camera);
      },
      SHOW_PYRAMIDS(state) {
        state.scene.add(...state.pyramids);
        state.renderer.render(state.scene, state.camera);
      },
      SWITCH_TO_PHONE(state){
        // state.transformControls.setMode( 'rotate' );
        // state.renderer.render(state.scene, state.camera);

        state.transformControls.detach();
        state.transformControls2.detach();
        state.scene.remove(state.transformControls)
        state.scene.remove(state.transformControls2)
        state.scene.remove(state.TextObjects[state.CurrentAppliedTextIndex])

        state.transformControls.attach( state.fullMesh );
        state.scene.add( state.transformControls );
    
        state.transformControls2.attach( state.fullMesh );
        state.scene.add( state.transformControls2 );
    
      },
      SWITCH_TO_TEXT(state,index){
        // state.transformControls.setMode( 'translate' );
        // state.renderer.render(state.scene, state.camera);
        console.log(index)
        

        // this will be used for multi text changes
        state.transformControls.detach();
        state.transformControls2.detach();
        state.scene.remove(state.transformControls);
        state.scene.remove(state.transformControls2);

        state.scene.remove(state.TextObjects[state.CurrentAppliedTextIndex]);
        state.selectedIndex = state.CurrentAppliedTextIndex;

        // state.textMesh1 = new Mesh( state.TextObjects[index], state.materials2 );
        // state.scene.remove(state.fullMesh)
        state.scene.add(state.TextObjects[state.CurrentAppliedTextIndex]);

        if(state.IsMobilePhone == false){
          state.transformControls.attach( state.TextObjects[state.CurrentAppliedTextIndex]);
          state.scene.add( state.transformControls );
          
          state.transformControls2.attach( state.TextObjects[state.CurrentAppliedTextIndex]);
          state.scene.add( state.transformControls2 );
        }

        state.renderer.render(state.scene, state.camera);
        


      },
      ADD_OBJECT(state){

        state.transformControls.detach();
        state.transformControls2.detach();
        state.scene.remove(state.transformControls);
        state.scene.remove(state.transformControls2);



        // let font = undefined;


        const height = 20,
          size = state.textSize,
          // hover = 0,
  
          curveSegments = 1,
  
          bevelThickness = 2,
          bevelSize = 1.5;


        state.textGeo = new TextGeometry( state.text, {

          font: state.currentFont,

          size: size,
          height: height,
          curveSegments: curveSegments,

          bevelThickness: bevelThickness,
          bevelSize: bevelSize,
          bevelEnabled: false

        } );

        state.textGeo.computeBoundingBox();

        // const centerOffset = - 0.5 * ( state.textGeo.boundingBox.max.x - state.textGeo.boundingBox.min.x );
        // const centerOffsetY = - 0.5 * ( state.textGeo.boundingBox.max.y - state.textGeo.boundingBox.min.y );

        
        state.textGeo.center()

        // var mesh = state.textGeo.toNonIndexed().toJSON();


        // const text = JSON.stringify(mesh);
        // var mesh = state.textGeo.toJSON();

        // if(state.selectedIndex != null){
        //   state.scene.remove(state.TextObjects[state.selectedIndex])
        // }
        console.log("REACHED HERE")
        
        state.textMesh1 = new Mesh( state.textGeo, state.materials2 );
        state.textMesh1.position.z = 1
        // state.selectedIndex = state.TextObjects.length -1


        console.log("Parsed Geo")

        if(state.CurrentAppliedTextIndex == null){
          state.CurrentAppliedTextIndex = 0;
        } else{
          state.CurrentAppliedTextIndex ++;
        }

        if(state.TextObjects.length == state.CurrentAppliedTextIndex){
          state.TextObjects.push(state.textMesh1);
          
        } else{
          state.TextObjects[state.CurrentAppliedTextIndex] = state.textMesh1

        }
        
        state.TextObject = state.textMesh1

        state.scene.add(state.TextObjects[state.CurrentAppliedTextIndex])
      
      },
      REMOVE_OBJECT(state,index){
        state.transformControls.detach();
        state.transformControls2.detach();
        state.scene.remove(state.transformControls);
        state.scene.remove(state.transformControls2);
        state.scene.remove(state.TextObjects[state.CurrentAppliedTextIndex]);
        
        

        state.TextObjects.splice(state.CurrentAppliedTextIndex,1);
        state.TextObject = null;

        if(state.CurrentAppliedTextIndex == 0){
          state.CurrentAppliedTextIndex = null
        } else{
          state.CurrentAppliedTextIndex --
        }

        state.renderer.render(state.scene, state.camera);
        console.log("deleted index:", index)
      },
      UNDO_CHANGES(state){        
        state.scene.remove(state.fullMesh);
        if(state.CurrentAppliedMeshIndex == 0){
          state.CurrentAppliedMeshIndex = null;
          state.fullMesh = state.currentPhoneMesh;
        } else{
          state.CurrentAppliedMeshIndex --;
          state.fullMesh = state.AppliedMeshList[state.CurrentAppliedMeshIndex];
        }        
        state.scene.add(state.fullMesh);
        state.renderer.render(state.scene, state.camera);

      },
      REDO_CHANGES(state){        
        state.scene.remove(state.fullMesh);
        if(state.CurrentAppliedMeshIndex == null){
          state.CurrentAppliedMeshIndex = 0;
        } else{
          state.CurrentAppliedMeshIndex ++;          
        }
        state.fullMesh = state.AppliedMeshList[state.CurrentAppliedMeshIndex];  
        state.scene.add(state.fullMesh);
        state.renderer.render(state.scene, state.camera);
        
      },
      LOAD_FONT(){

      },
      LOADED_STL(state){
        state.transformControls.detach();
        state.transformControls2.detach();
        state.scene.remove(state.transformControls);
        state.scene.remove(state.transformControls2);
        state.scene.remove(state.fullMesh);
        state.scene.remove(state.TextObjects[state.CurrentAppliedTextIndex])
        state.AppliedMeshList.length = 0;
        state.TextObjects.length = 0;
        state.CurrentAppliedTextIndex = null;
        state.CurrentAppliedMeshIndex = null;
        state.fullMesh = state.currentPhoneMesh;
        state.scene.add(state.fullMesh);
        state.renderer.render(state.scene, state.camera);
        
      },
      EXPORT_STL(state) {
        const link = document.createElement( 'a' );
        link.style.display = 'none';
        document.body.appendChild( link );

        const result = state.exporter.parse(state.fullMesh)

        link.href = URL.createObjectURL( new Blob( [ result ], { type: 'text/plain' } ) );
				link.download = 'test.stl';
				link.click();
      },
      TEMP_SAVE_EXPORT_STL(state) {
        var tempExpt = state.fullMesh;
        // tempExpt.rotation.set( 0,0 , -Math.PI / 2);
        const result = state.exporter.parse(tempExpt)

        state.exportedmesh = result
        // localStorage.setItem("saved-cover", result)

        // console.log( localStorage.getItem("saved-cover") )

        // link.href = URL.createObjectURL( new Blob( [ result ], { type: 'text/plain' } ) );
				// link.download = 'test.stl';
				// link.click();
      },
    },
    actions: {
    INIT({ state, commit, dispatch }, { width, height, el }) {
      return new Promise(resolve => {
        commit("SET_VIEWPORT_SIZE", { width, height });
        dispatch("LOAD_STL", state.CurrentPhoneMeshPath);
        commit("INITIALIZE_RENDERER", el);
        commit("INITIALIZE_CAMERA");
        commit("INITIALIZE_CONTROLS");
        
        commit("INITIALIZE_SCENE");
        commit("INITIALIZE_TRANSFORM_CONTROLS");
        // Initial scene rendering
        // state.renderer.render(state.scene, state.camera);
        // Add an event listener that will re-render
        // the scene when the controls are changed
        // state.controls.addEventListener("change", () => {
        //   state.renderer.render(state.scene, state.camera);
        // });
        state.orbit.addEventListener("change", () => {
          state.renderer.render(state.scene, state.camera);
        });
        
        resolve();
      });
    },
    ANIMATE({ state, dispatch }) {
        window.requestAnimationFrame(() => {
          dispatch("ANIMATE");
          state.orbit.update();
                    
        });
    },

    async REGENERATE_GEOMETRY({state}){

       console.log("222")
        
        state.transformControls.detach();
        state.transformControls2.detach();
        state.scene.remove(state.transformControls);
        state.scene.remove(state.transformControls2);
        state.scene.remove(state.fullMesh)
        state.scene.remove(state.TextObjects[state.CurrentAppliedTextIndex])

        var temp
        if(state.CurrentAppliedMeshIndex != null){
          temp = state.AppliedMeshList[state.CurrentAppliedMeshIndex]
          state.CurrentAppliedMeshIndex ++;
        } else{
          temp = state.fullMesh
          state.CurrentAppliedMeshIndex = 0
        }
        
        if(state.CurrentAppliedTextIndex != null){
          // state.TextObjects.forEach(text => {
            console.log("MMLLLJHH")
            console.log(temp)
            console.log(state.TextObjects[state.CurrentAppliedTextIndex])
            const sBSP = new ThreeBSP(state.TextObjects[state.CurrentAppliedTextIndex]);            
            
            const bBSP = new ThreeBSP(temp);
            console.log("HEreresda")
    
            const sub = bBSP.subtract(sBSP);
            temp = sub.toMesh();
            console.log("1111111111111@@@@@@@@")
            console.log(temp)
          // });
        }
        if(state.currentColor == 'black'){
          temp.material = state.blackMaterials
        } else if(state.currentColor == 'white'){
          temp.material = state.whiteMaterials
        } else if(state.currentColor == 'gray'){
          temp.material = state.grayMaterials
        } else{
          temp.material = state.materials
        }
        
        state.fullMesh = temp
        
       

        // var bsp1 = new ThreeBSP(mesh1)
        // var bsp2 = new ThreeBSP(mesh2)
        // var bsp = bsp1.subtract(bsp2)
        // var mesh = bsp.toMesh(material)
      
        
        state.scene.add(state.fullMesh);
        state.renderer.render(state.scene, state.camera);
      console.log("Exited loop")
      console.log(state.AppliedMeshList.length)
     
      

      if(state.AppliedMeshList.length == state.CurrentAppliedMeshIndex){
        state.AppliedMeshList.push(temp);
        console.log("Pushed")
        
      } else{
        state.AppliedMeshList[state.CurrentAppliedMeshIndex] = temp
        state.AppliedMeshList.length = state.CurrentAppliedMeshIndex +1
      }

      state.TextObject = null;
      // state.fullMesh = temp
      // commit("SET_FULL_MESH", temp)
      // console.log("Commited Msh")
      
      
      return true
    },

    async LOAD_STL({state},path){
      const loaderSTL = new STLLoader();
      if(state.fullMesh != null){
        state.scene.remove( state.fullMesh );
      }
        

      loaderSTL.load( path, function ( geometry ) {

          // const material = new THREE.MeshPhongMaterial( { color: 0xff9c7c, specular: 0x494949, shininess: 200 } );
        
          // var geo = new THREE.Geometry().fromBufferGeometry(geometry);
          var newMat = null
          if(state.currentColor == 'black'){
            newMat= state.blackMaterials
          } else if(state.currentColor == 'white'){
            newMat = state.whiteMaterials
          } else if(state.currentColor == 'gray'){
            newMat = state.grayMaterials
          } else{
            newMat = state.materials
          }
          state.currentPhoneMesh = new Mesh( new THREE.Geometry().fromBufferGeometry(geometry), newMat );

          console.log('herere: ', geometry)

           state.currentPhoneMesh.position.set( 0, 0, 0 );
           state.currentPhoneMesh.rotation.set( 0,Math.PI , -Math.PI / 2);
           state.currentPhoneMesh.scale.set( 1, 1, 1 );

           state.currentPhoneMesh.castShadow = true;
           state.currentPhoneMesh.receiveShadow = true;

          // state.currentPhoneMesh = mesh;
          // state.scene.add( state.currentPhoneMesh );
          // state.renderer.render(state.scene, state.camera);

          state.transformControls.detach();
          state.transformControls2.detach();
          state.scene.remove(state.transformControls);
          state.scene.remove(state.transformControls2);
          state.scene.remove(state.fullMesh);
          state.scene.remove(state.TextObjects[state.CurrentAppliedTextIndex])
          state.AppliedMeshList.length = 0;
          state.TextObjects.length = 0;
          state.CurrentAppliedTextIndex = null;
          state.CurrentAppliedMeshIndex = null;
          state.fullMesh = state.currentPhoneMesh;
          state.scene.add(state.fullMesh);
          state.renderer.render(state.scene, state.camera);

        },
        (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
          if((xhr.loaded / xhr.total) * 100 == 100){
            // commit("LOADED_STL")
          }

      },
      (error) => {
          console.log(error)
      } );
    },
    async TAKE_SCREENSHOT({state}){
      var strMime = "image/jpeg";
      state.screenShotData = state.renderer.domElement.toDataURL(strMime);
    },

    GET_CAMERA({state}){
      console.log("CAMERA_POSITION")
      console.log(state.camera.position)
      console.log("CAMERA_ROTATION")
      console.log(state.camera.rotation)
    }

    // async COUNTING_LOOP(){
    //   worker.postMessage("test test")
    // },


   
    }

});

// worker.onmessage = function(message){
//   store.commit(message.data.type, message.data.payload)
// };

export default store