<template>
  <div class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

    <div class=" orderPanel  absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-sm rounded bg-slate-200">
      <div class="flex border-b border-blue-400 p-2">
        <div class="ml-auto font-bold text-xl">LOG IN</div>
        <div v-on:click="close()" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </div>
      </div>
      
      <div class=" flex flex-col p-6">
          
         <input class=" mx-4 my-2" type="text" v-model="username" placeholder="Enter User Name" />
         <input class=" mx-4 my-2" type="password" v-model="password" placeholder="Enter Password" />

         <button v-on:click="logIn" 
          class=" bg-gray-200 font-bold text-gray-600 cursor-pointer shadow p-2 mx-auto">
         LOG IN</button>

         <div class=" mt-2" > New user? <span v-on:click="SignUp()" class=" font-semibold cursor-pointer"> SignUp Here</span>
         </div>
      </div>
    </div>
    
    

  
  </div>
</template>

<script>
// @ is an alias to /src

import axios from "axios";
export default {
  name: 'LogIn',
  components: {
    // signUpPage: SignUpPage,
  },
  data () {
       return{
        username:'',
        password:'',
        BackendUrl: "https://labsapi.packymstechnology.com"
       }
      },
  methods:{
   async logIn(){
    let dev = process.env.VUE_APP_DEVELOPMENT;    
    if (dev == "true") {
      console.log("is in dev mode")
      this.BackendUrl = "";
    }
      
    this.$parent.showLoading(true)
       
    try {
      let result = await axios.post(this.BackendUrl + "/users/login", {
          username:this.username,
          password:this.password
        });
        if(result.status == 200){
          // alert("You Have Logged In")
          this.$parent.setMessageBox("Welcome "+ this.username, "accepted")
          localStorage.setItem('account', JSON.stringify(result.data))
          //bring up login page
          this.$parent.userLoggedin = true;
          this.$parent.showLogin(false);
          location.reload()


        } else {
          alert("there was an error")
        }
    } catch (error) {
      const code = error.response.status
      if(code == 400 || code == 404 || code == 401){
        this.$parent.setMessageBox("Wrong username or password", "error")
      } else{
        this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
      }
    }
   

        
        this.$parent.showLoading(false)
      
    },
    SignUp(){
      this.$parent.showSignUp(true);
      this.$parent.showLogin(false);
    },
    close(){
      this.$parent.showLogin(false);
    }
  }
  
}
</script>
<style>
  .contPage{
    height: -webkit-fill-available;
  }
</style>
