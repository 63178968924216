<template>
  <div class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

    <div class=" orderPanel  absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-sm rounded bg-slate-200">
      <div class="flex border-b border-blue-400 p-2">
        <div class="ml-auto font-bold text-xl">Update Profile Info</div>
        <div v-on:click="close()" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </div>
      </div>

      <div class=" md:h-auto" >
        
        <div class=" flex flex-col p-6">

           <input class=" m-2 md:m-4" type="text" v-model="firstname" placeholder="Enter First Name" />
           <input class=" m-2 md:m-4" type="text" v-model="lastname" placeholder="Enter Last Name" />
           <input class=" m-2 md:m-4" type="text" v-model="email" placeholder="Enter Email" />
           <input class=" m-2 md:m-4" type="number" v-model="phone" placeholder="Enter Phone Number" />

           <p v-if="errors.length">
            <b>Please correct the following error(s):</b>
              <ul class=" text-red-500">
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </p>
          
           <button 
            class=" bg-gray-200 font-bold text-gray-600 cursor-pointer shadow p-2 mx-auto" 
            v-on:click="updateProfile()"> 
            Update
          </button>
          
        
        </div>
      </div>
    
    
    </div>

  
  </div>
</template>

<script>
// @ is an alias to /src

import axios from "axios";
export default {
  name: 'UpdateProfileInfo',
  components: {
    // signUpPage: SignUpPage,
  },
  data () {
       return{
        
        firstname:'',
        lastname:'',
        email:'',
        phone:null,

        errors: [],
        BackendUrl: "https://labsapi.packymstechnology.com"
       }
      },
  methods:{
   async updateProfile(){
    this.checkForm();
     if(this.errors.length){
       // this.warnShakeError()
        console.log("Still have an Error")
      }
      else{
        this.$parent.showLoading(true)
        let dev = process.env.VUE_APP_DEVELOPMENT;    
        if (dev == "true") {
          console.log("is in dev mode")
          this.BackendUrl = "";
        }
        try {
          let result = await axios.put(this.BackendUrl + "/users", {
          username: JSON.parse(localStorage.getItem('account')).user.username,
          first_name:this.firstname,
          last_name:this.lastname,
          phone_number:this.phone,
          email: this.email,
          
        });

        console.log(result)

        if(result.status == 200){
          // alert("Signed up ready to login")
          this.updateLocalStorageData(result.data)
          this.$parent.showUpdateProfile(false);
          // localStorage.setItem('account', JSON.stringify(result.data))
          this.$parent.setMessageBox("Profile Info has been updated.", "accepted")
          this.$parent.showProfile(true)
        } else {
          alert("there was an error")
          
          
        }
        } catch (error) {
          const code = error.response.status
          if(code == 403){
            this.$parent.setMessageBox("Username or email already taken", "warning")
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
        }
        
        this.$parent.showLoading(false)
        }
    
    },
    updateLocalStorageData(user){
      var data = JSON.parse(localStorage.getItem('account'))

      data.user.first_name = user.first_name
      data.user.last_name = user.last_name
      data.user.phone_number = user.phone_number
      data.user.email = user.email
      localStorage.setItem('account', JSON.stringify(data))

    },
    checkForm(){
            this.errors = [];
           
            if (!this.firstname) {
              this.errors.push("First name required.");
            }
            if (!this.lastname) {
              this.errors.push("Last name required.");
            }
            if (!this.email) {
              this.errors.push('Email required.');
            } else if (!this.validEmail(this.email)) {
              this.errors.push('Valid email required.');
            }
            if (!this.phone) {
              this.errors.push("Phone Number required.");
            }
           
        },
        validEmail(email){
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
   
    close(){
      this.$parent.showUpdateProfile(false);
    }

  },
  mounted(){
    let user = JSON.parse(localStorage.getItem('account')).user;
    if(user) {
      this.firstname = user.first_name;
      this.lastname = user.last_name;
      this.phone = user.phone_number;
      this.email = user.email;
    }
  }
  
}
</script>

<style scoped>

.panel{
  max-height: 80%;
}

</style>
