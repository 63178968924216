<template>
  <div class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

    <div class=" orderPanel  absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-md rounded bg-slate-200">
      <div class="flex border-b border-blue-400 p-2">
        <div class="ml-auto font-bold text-xl">All Communities</div>
        <div v-on:click="close()" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </div>
      </div>
      
      <div class=" mx-auto mt-2">
        <div v-on:click="showCreateCommunityPanel(true)" class=" p-2  bg-green-300 rounded-md active:scale-75 hover:scale-105">
          Add New Community
        </div>
      </div>

      
      
      <div class=" flex flex-col overflow-y-auto mt-2 w-full text-sm">
        
        <div v-for="(data,i) in data" :key="i" class=" w-full p-4">
          <div class="flex flex-col w-full h-full rounded bg-gray-100 p-4">
          
            <div class=" flex flex-col h-fit w-full text-center">
              <div class=" flex flex-row space-x-2 items-center m-auto text-lg"> 
                <div>{{ data.community_name }} </div>
                <div v-if="data.community_status == 'PENDING'" class=" w-6 h-6 bg-slate-100 border-slate-300 rounded-full">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-gray-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.348 14.652a3.75 3.75 0 0 1 0-5.304m5.304 0a3.75 3.75 0 0 1 0 5.304m-7.425 2.121a6.75 6.75 0 0 1 0-9.546m9.546 0a6.75 6.75 0 0 1 0 9.546M5.106 18.894c-3.808-3.807-3.808-9.98 0-13.788m13.788 0c3.808 3.807 3.808 9.98 0 13.788M12 12h.008v.008H12V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                  </svg>
                </div>
                <div v-else-if="diff_minutes(data.last_online_ping) < onlineTimeChecker" class=" w-6 h-6 bg-slate-100 border-slate-300 rounded-full">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-green-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.348 14.652a3.75 3.75 0 0 1 0-5.304m5.304 0a3.75 3.75 0 0 1 0 5.304m-7.425 2.121a6.75 6.75 0 0 1 0-9.546m9.546 0a6.75 6.75 0 0 1 0 9.546M5.106 18.894c-3.808-3.807-3.808-9.98 0-13.788m13.788 0c3.808 3.807 3.808 9.98 0 13.788M12 12h.008v.008H12V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                  </svg>
                </div>
                <div v-else class=" w-6 h-6 bg-slate-100 border-slate-300 rounded-full">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-red-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m3 3 8.735 8.735m0 0a.374.374 0 1 1 .53.53m-.53-.53.53.53m0 0L21 21M14.652 9.348a3.75 3.75 0 0 1 0 5.304m2.121-7.425a6.75 6.75 0 0 1 0 9.546m2.121-11.667c3.808 3.807 3.808 9.98 0 13.788m-9.546-4.242a3.733 3.733 0 0 1-1.06-2.122m-1.061 4.243a6.75 6.75 0 0 1-1.625-6.929m-.496 9.05c-3.068-3.067-3.664-7.67-1.79-11.334M12 12h.008v.008H12V12Z" />
                  </svg>
                </div>
              </div>  
            </div>

            <div class=" flex w-full h-auto">
              <div class=" flex flex-col items-center h-fit w-1/2">
                <div>
                  <div class="mt-2 flex flex-col items-center text-center">
                    <div class=" w-4 h-4 bg-slate-100 border-slate-300 rounded-full">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                      </svg>                 
                    </div>
                    <div>{{ data.province }},</div>
                    <div>{{ data.city }},</div>
                    <div>{{ data.area }},</div>
                  </div> 
                </div>
                <div>
                  <div class="mt-2 flex items-center text-center">
                    <div class=" w-4 h-4 bg-slate-100 border-slate-300 rounded-full mr-1">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                      </svg>                 
                    </div>
                    <div> K{{ data.running_costs }}</div>
                  </div> 
                  <div class="mt-2 flex items-center text-center">
                    <div class=" w-4 h-4 bg-slate-100 border-slate-300 rounded-full mr-1">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                      </svg>
                
                    </div>
                    <div> {{ convertDate(data.created_at) }}</div>
                  </div> 
                </div>
              </div>
              <div class=" flex flex-col items-center h-fit w-1/2">
                <div>
                  <div class=" mt-2 flex flex-col items-center text-center">
                    <div class=" w-5 h-5 bg-slate-100 border-slate-300 rounded-full">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.288 15.038a5.25 5.25 0 0 1 7.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 0 1 1.06 0Z" />
                      </svg>
                    </div>
                    <div>{{ data.coverage }}M</div>
                  </div>
                  <div class=" mt-2 flex flex-col items-center text-center">
                    <div class=" w-5 h-5 bg-slate-100 border-slate-300 rounded-full">
                      
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12.75 19.5v-.75a7.5 7.5 0 0 0-7.5-7.5H4.5m0-6.75h.75c7.87 0 14.25 6.38 14.25 14.25v.75M6 18.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                      </svg>


                    </div>
                    <div>{{ data.current_bandwidth }}Mbps</div>

                  </div>                  
                </div>
                <button v-if="data.id != 0" v-on:click="showEditPanelButton(data.id)" class="  bg-white mt-3  text-gray-600 font-medium cursor-pointer shadow px-2 py-1 ml-auto"> Edit </button>
              </div>
            </div>
          
          
          
            <!-- <div class=" flex flex-col h-fit w-full">
              <div class=""> Community Name: {{ data.community_name }} </div>  
            </div>

            <div class="mt-2 flex flex-row">
              <div class=""> Country: {{ data.country }} </div>  
            </div>

            <div class="mt-2 flex flex-row">
              <div class=" ">Province: {{ data.province }}</div>
            </div>

            <div class="mt-2 flex flex-row text-sm font">
              <div class=" flex flex-col">
              <div class=" ">City: {{ data.city }}</div>
              <div>
                <div>
                  <div class=" mt-2 ">Area: {{ data.area }}</div>
                </div>

                <div>
                  <div class=" mt-2 ">Status: {{ data.community_status }}</div>
                </div>

                <div>
                  <div class=" mt-2 ">Coverage: {{ data.coverage }} Meters</div>
                </div>

                <div>
                  <div class=" mt-2 ">Bandwidth: {{ data.current_bandwidth }} Mbps</div>
                </div>

                <div>
                  <div class=" mt-2 ">Running Costs: K{{ data.running_costs }}</div>
                </div>
                <div>
                  <div class=" mt-2 ">Created at: {{ convertDate(data.created_at) }}</div>
                </div>
                 
              </div> 
              </div>
              <div class=" flex flex-col ml-auto">              
                <button v-if="data.id != 0" v-on:click="showEditPanelButton(data.id)" class="  bg-white mt-3  text-gray-600 font-medium cursor-pointer shadow px-2 py-1 ml-auto"> Edit </button>              
              </div>            
            </div> -->



            
          </div>         
          
        </div>

      </div>
    
    </div>

  
    <div v-if="showEdit" class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
      <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

      <div class=" orderPanel  absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-md rounded bg-slate-200">
        <div class="flex border-b border-blue-400 p-2">
          <div class="ml-auto font-bold text-xl">Edit Options {{  }}</div>
          <div v-on:click="showEditPanel(false)" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
          </div>
        </div>


        <div class=" flex flex-col overflow-y-auto mt-2 w-full text-sm">
          
          <div class=" mx-auto flex items-center flex-col  mt-2 mb-4">
            <div v-on:click="showEditCoveragePanel(true)" class=" w-fit p-2 m-2 bg-slate-100 border-slate-300 rounded-md active:scale-75 hover:scale-105">
              Edit Coverage
            </div>

            <div v-on:click="showEditStatusPanel(true)" class=" p-2 m-2 bg-slate-100 border-slate-300 rounded-md active:scale-75 hover:scale-105">
              Edit Community Status
            </div>
            <div v-on:click="showEditBandwidthPanel(true)" class=" p-2 m-2 bg-slate-100 border-slate-300 rounded-md active:scale-75 hover:scale-105">
              Edit Community Bandwidth
            </div>
            <div v-on:click="showEditRunningCostPanel(true)" class=" p-2 m-2 bg-slate-100 border-slate-300 rounded-md active:scale-75 hover:scale-105">
              Edit Community Running Costs
            </div>
          </div>

        </div>
      
      </div>
    
    </div>


    <div v-if="showEditCoverage" class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
      <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

      <div class=" orderPanel absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-sm rounded bg-slate-200">
      <div class="flex border-b border-blue-400 p-2">
        <div class="ml-auto font-bold text-xl">Edit Community Coverage</div>
        <div v-on:click="showEditCoveragePanel(false)" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </div>
      </div>

      <div class=" md:h-auto" >
        
        <div class=" flex flex-col p-6">
           <input class=" m-2 md:m-4" type="number" v-model="coverage" placeholder="Enter Coverage" />
           <!-- <input class=" m-2 md:m-4" type="number" v-model="status" placeholder="Enter Status" /> -->
           <!-- <select v-model="status" class=" w-full h-10 border mb-4 ">
                  <option  v-for="(communityStatus, index) in CommunityStatuses" v-bind:key="index">{{communityStatus}}</option>
                  <option :value="this.status" disabled hidden>Community Status</option>
            </select> -->

           <p v-if="errors.length">
            <b>Please correct the following error(s):</b>
              <ul class=" text-red-500">
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </p>
          
           <button 
            class=" bg-gray-200 font-bold text-gray-600 cursor-pointer shadow p-2 mx-auto" 
            v-on:click="UpdateCoverageButton()"> 
            Update
          </button>
          
        </div>
      </div>
    
    
      </div>

    </div>
    <div v-if="showEditStatus" class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
      <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

      <div class=" orderPanel absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-sm rounded bg-slate-200">
      <div class="flex border-b border-blue-400 p-2">
        <div class="ml-auto font-bold text-xl">Edit Community Status</div>
        <div v-on:click="showEditStatusPanel(false)" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </div>
      </div>

      <div class=" md:h-auto" >
        
        <div class=" flex flex-col p-6">
           <!-- <input class=" m-2 md:m-4" type="number" v-model="coverage" placeholder="Enter Coverage" /> -->
           <!-- <input class=" m-2 md:m-4" type="number" v-model="status" placeholder="Enter Status" /> -->
           <select v-model="status" class=" w-full h-10 border mb-4 ">
                  <option  v-for="(communityStatus, index) in CommunityStatuses" v-bind:key="index">{{communityStatus}}</option>
                  <option :value="this.status" disabled hidden>Community Status</option>
            </select>

           <p v-if="errors.length">
            <b>Please correct the following error(s):</b>
              <ul class=" text-red-500">
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </p>
          
           <button 
            class=" bg-gray-200 font-bold text-gray-600 cursor-pointer shadow p-2 mx-auto" 
            v-on:click="UpdateStatusButton()"> 
            Update
          </button>
          
        </div>
      </div>
    
    
      </div>

    </div>
    <div v-if="showEditBandwidth" class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
      <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

      <div class=" orderPanel absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-sm rounded bg-slate-200">
      <div class="flex border-b border-blue-400 p-2">
        <div class="ml-auto font-bold text-xl">Edit Community Bandwidth</div>
        <div v-on:click="showEditBandwidthPanel(false)" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </div>
      </div>

      <div class=" md:h-auto" >
        
        <div class=" flex flex-col p-6">
           <input class=" m-2 md:m-4" type="number" v-model="bandwidth" placeholder="Enter Bandwidth" />
           <!-- <input class=" m-2 md:m-4" type="number" v-model="status" placeholder="Enter Status" /> -->
           <!-- <select v-model="status" class=" w-full h-10 border mb-4 ">
                  <option  v-for="(communityStatus, index) in CommunityStatuses" v-bind:key="index">{{communityStatus}}</option>
                  <option :value="this.status" disabled hidden>Community Status</option>
            </select> -->

           <p v-if="errors.length">
            <b>Please correct the following error(s):</b>
              <ul class=" text-red-500">
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </p>
          
           <button 
            class=" bg-gray-200 font-bold text-gray-600 cursor-pointer shadow p-2 mx-auto" 
            v-on:click="UpdateBandwidthButton()"> 
            Update
          </button>
          
        </div>
      </div>
    
    
      </div>

    </div>
  
    <div v-if="showEditRunningCost" class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
      <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

      <div class=" orderPanel absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-sm rounded bg-slate-200">
      <div class="flex border-b border-blue-400 p-2">
        <div class="ml-auto font-bold text-xl">Edit Running Costs</div>
        <div v-on:click="showEditRunningCostPanel(false)" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </div>
      </div>

      <div class=" md:h-auto" >
        
        <div class=" flex flex-col p-6">
           <input class=" m-2 md:m-4" type="number" v-model="runningCost" placeholder="Enter Running Costs" />

           <p v-if="errors.length">
            <b>Please correct the following error(s):</b>
              <ul class=" text-red-500">
                <li v-for="error in errors" :key="error">{{ error }}</li>
              </ul>
            </p>
          
           <button 
            class=" bg-gray-200 font-bold text-gray-600 cursor-pointer shadow p-2 mx-auto" 
            v-on:click="UpdateRunnungCostButton()"> 
            Update
          </button>
          
        </div>
      </div>
    
    
      </div>

    </div>

    <createCommunityPage v-if="showCreateCommunity"  ></createCommunityPage>
  
  </div>
  
</template>

<script>
// @ is an alias to /src

import axios from "axios";
import CreateCommunityView from "@/views/admin/CreateCommunity.vue";
import { mapGetters, mapMutations,mapActions } from "vuex";



export default {
  name: 'OrderPage',
  components: {
    createCommunityPage: CreateCommunityView,
    
  },
  data () {
       return{
        details:'',
        amount:'',
        // status:'',
        order_date:'',
        data:[],
        serverTime:"",
        onlineTimeChecker: 10,
        pageNumber: 1,
        PageSize: 100,

        selectedCommunityID:1,
        coverage:0,
        status:"",
        bandwidth:0,
        runningCost:0,
        CommunityStatuses:["NOT ACTIVE","PENDING", "ACTIVE"],

        showEdit:false,
        showEditCoverage:false,
        showEditStatus:false,
        showEditBandwidth:false,
        showEditRunningCost:false,
        previewCase: false,
       
        errors: [],
        showCreateCommunity: false,
        BackendUrl: "https://labsapi.packymstechnology.com"
       }
      },

      computed: {
      ...mapGetters(["GET_SCREENSHOT"]),

    },
  methods:{
    ...mapMutations([
        "REGENERATE_GEOMETRY", 
        "LOADED_STL",
        "SET_COLOR",
        "SET_SCREENSHOT_POSITION"      
      ]),
      ...mapActions(["REGENERATE_GEOMETRY","LOAD_STL","TAKE_SCREENSHOT"]),
    
    
    openEditInfo(){
      this.$parent.showUpdateProfile(true);
      this.$parent.showProfile(false);
    }, 
    close(){
      this.$parent.showAllCommunities(false);
    },
   
    showPayment(amount, ref){
      this.$parent.showPayment(true, amount, ref);
    },
    
    async loadOrders(){
      this.$parent.showLoading(true)
      let dev = process.env.VUE_APP_DEVELOPMENT;    
    if (dev == "true") {
      console.log("is in dev mode")
      this.BackendUrl = "";
    }
         
      try {
        let result = await axios.get(this.BackendUrl + "/admin/communities", {
            headers:{
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('account')).access_token}`
          }
          });
          if(result.status == 200){
            // alert(" Order fetched")
            this.data = result.data
            // this.close();
  
          } else {
            alert("there was an error with placing Order")
          }
      } catch (error) {
        const code = error.response.status
        
          if(code == 401){
            this.close();
            this.$parent.setMessageBox("Please LogIn Again", "warning")
            this.$parent.showLogin(true);
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
      }
     
  
          
          this.$parent.showLoading(false)
    },
    
    async GetServerTime(){
      this.$parent.showLoading(true)
      let dev = process.env.VUE_APP_DEVELOPMENT;    
    if (dev == "true") {
      console.log("is in dev mode")
      this.BackendUrl = "";
    }
         
      try {
        let result = await axios.get(this.BackendUrl + "/time", {
            headers:{
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('account')).access_token}`
          }
          });
          if(result.status == 200){
            // alert(" Order fetched")
            this.serverTime = result.data
            console.log(this.serverTime)
            // this.close();
  
          } else {
            alert("there was an error with getting current time")
          }
      } catch (error) {
        // const code = error.response.status
        
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          
      }
     
  
          
          this.$parent.showLoading(false)
    },

    GetColorFromDetails(details){
      let options = details.split(",");
      let color = options[1].trim();
      return color
    },

    SetPreview(details, path){
      let dev = process.env.VUE_APP_DEVELOPMENT;  
      if (dev == "true") {
      console.log("is in dev mode")
      this.BackendUrl = "http://localhost:8080";
      path = this.BackendUrl + path.substring(1)
    }
      let color = this.GetColorFromDetails(details)
      this.SET_COLOR(color);
      this.LOAD_STL(path).then(() => {
            setTimeout(()=>{
              // this.LOADED_STL()
              this.$parent.showLoading(false)
              this.close()
            },1000)
          // this.applyChanges();
          });
    },
    showLoading(show){
      this.$parent.showLoading(show)
    },
    showEditPanelButton(community_id){
      this.selectedCommunityID = community_id;
      this.showEditPanel(true);
    },
    showEditPanel(show){
      this.showEdit = show;
    },
    showEditCoveragePanel(show){
      this.showEditCoverage = show;
    },
    showEditStatusPanel(show){
      this.showEditStatus = show;
    },
    showEditBandwidthPanel(show){
      this.showEditBandwidth = show;
    },
    showEditRunningCostPanel(show){
      this.showEditRunningCost = show;
    },
    showCreateCommunityPanel(show){
      this.showCreateCommunity = show;
    },

    async UpdateCoverageButton(){
      this.errors = [];
      if (!this.coverage) {
        this.errors.push("Coverage required.");
      }
     if(this.errors.length){
       // this.warnShakeError()
        console.log("Still have an Error")
      }
      else{
        this.$parent.showLoading(true)
        let dev = process.env.VUE_APP_DEVELOPMENT;    
        if (dev == "true") {
          console.log("is in dev mode")
          this.BackendUrl = "";
        }
        try {
          let result = await axios.put(this.BackendUrl + "/admin/communities/update_coverage", {
          // username: JSON.parse(localStorage.getItem('account')).user.username,
          id:Number(this.selectedCommunityID),
          coverage:Number(this.coverage),
          
        },{
            headers:{
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('account')).access_token}`
          }
        });

        console.log(result)

        if(result.status == 200){
          this.$parent.showUpdateProfile(false);
          // localStorage.setItem('account', JSON.stringify(result.data))
          this.$parent.setMessageBox("Profile Info has been updated.", "accepted")
          this.showEditCoveragePanel(false)
          this.showEditPanel(false)
          this.close()
          
        } else {
          alert("there was an error")
          
          
        }
        } catch (error) {
          const code = error.response.status
          if(code == 403){
            this.$parent.setMessageBox("Something went Wrong", "warning")
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
        }
        
        this.$parent.showLoading(false)
        }
    },
    async UpdateStatusButton(){
      this.errors = [];
      if (!this.status) {
        this.errors.push("Status required.");
      }
     if(this.errors.length){
       // this.warnShakeError()
        console.log("Still have an Error")
      }
      else{
        this.$parent.showLoading(true)
        let dev = process.env.VUE_APP_DEVELOPMENT;    
        if (dev == "true") {
          console.log("is in dev mode")
          this.BackendUrl = "";
        }
        try {
          let result = await axios.put(this.BackendUrl + "/admin/communities/update_status", {
          // username: JSON.parse(localStorage.getItem('account')).user.username,
          id:Number(this.selectedCommunityID),
          status:this.status,
          
        },{
            headers:{
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('account')).access_token}`
          }
        });

        console.log(result)

        if(result.status == 200){
          // this.$parent.showUpdateProfile(false);
          // localStorage.setItem('account', JSON.stringify(result.data))
          this.$parent.setMessageBox("Community Status Updated.", "accepted")
          this.showEditStatusPanel(false)
          this.showEditPanel(false)
          this.close()
          
        } else {
          alert("there was an error")
          
          
        }
        } catch (error) {
          const code = error.response.status
          if(code == 403){
            this.$parent.setMessageBox("Something went Wrong", "warning")
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
        }
        
        this.$parent.showLoading(false)
        }
    },
    async UpdateBandwidthButton(){
      this.errors = [];
      if (!this.bandwidth) {
        this.errors.push("Bandwidth required.");
      }
     if(this.errors.length){
       // this.warnShakeError()
        console.log("Still have an Error")
      }
      else{
        this.$parent.showLoading(true)
        let dev = process.env.VUE_APP_DEVELOPMENT;    
        if (dev == "true") {
          console.log("is in dev mode")
          this.BackendUrl = "";
        }
        try {
          let result = await axios.put(this.BackendUrl + "/admin/communities/update_bandwidth", {
          // username: JSON.parse(localStorage.getItem('account')).user.username,
          id:Number(this.selectedCommunityID),
          bandwidth:Number(this.bandwidth),
          
        },{
            headers:{
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('account')).access_token}`
          }
        });

        console.log(result)

        if(result.status == 200){
          // this.$parent.showUpdateProfile(false);
          // localStorage.setItem('account', JSON.stringify(result.data))
          this.$parent.setMessageBox("Community Bandwidth Updated.", "accepted")
          this.showEditBandwidthPanel(false)
          this.showEditPanel(false)
          this.close()
          
        } else {
          alert("there was an error")
          
          
        }
        } catch (error) {
          const code = error.response.status
          if(code == 403){
            this.$parent.setMessageBox("Something went Wrong", "warning")
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
        }
        
        this.$parent.showLoading(false)
        }
    },
    async UpdateRunnungCostButton(){
      this.errors = [];
      if (!this.runningCost) {
        this.errors.push("Running Cost required.");
      }
     if(this.errors.length){
       // this.warnShakeError()
        console.log("Still have an Error")
      }
      else{
        this.$parent.showLoading(true)
        let dev = process.env.VUE_APP_DEVELOPMENT;    
        if (dev == "true") {
          console.log("is in dev mode")
          this.BackendUrl = "";
        }
        try {
          let result = await axios.put(this.BackendUrl + "/admin/communities/update_runningcost", {
          // username: JSON.parse(localStorage.getItem('account')).user.username,
          id:Number(this.selectedCommunityID),
          runningCost:Number(this.runningCost),
          
        },{
            headers:{
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('account')).access_token}`
          }
        });

        console.log(result)

        if(result.status == 200){
          // this.$parent.showUpdateProfile(false);
          // localStorage.setItem('account', JSON.stringify(result.data))
          this.$parent.setMessageBox("Community Running Costs Updated.", "accepted")
          this.showEditRunningCostPanel(false)
          this.showEditPanel(false)
          this.close()
          
        } else {
          alert("there was an error")
          
          
        }
        } catch (error) {
          const code = error.response.status
          if(code == 403){
            this.$parent.setMessageBox("Something went Wrong", "warning")
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
        }
        
        this.$parent.showLoading(false)
        }
    },
    


    setMessageBox(message,type){
      this.$parent.setMessageBox(message, type)
    },
    convertDate(date){
      const formatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' });
      return formatter.format(Date.parse(date))
    },

    diff_minutes(dt){
      var dt1 = new Date(dt);
      var dt2 = new Date(this.serverTime);
      var diff =(dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff));        
    }

    

  },
  mounted(){
    let user = localStorage.getItem('account');
    if(user) {
      this.GetServerTime();
      this.loadOrders();
    }
  }
  
}
</script>
<style scoped>
.orderPanel{
max-height: 85%;
}
</style>
