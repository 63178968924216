<template>
  <div class="home w-full flex md:px-20 mt-20 md:mt-40 justify-evenly">

    <router-link to="/Internet" class=" w-32 flex flex-col cursor-pointer active:scale-95 hover:scale-105">
      <div class=" p-6 bg-gray-100 rounded-3xl border border-gray-500 shadow-lg shadow-black ">
        <div class=" bg-slate-200 w-full h-20 rounded-full relative p-2 mt-5 shadow shadow-black ">
        <div class=" left-0 -top-7 w-full absolute ">

          <div class=" h-2 rounded-xl border-4 bg-blue-200 border-slate-200 mx-auto w-2/5 shadow-sm shadow-black"></div>
          <div class=" h-5 border-4 border-t-0 border-b-0 bg-blue-200 border-slate-200 mx-auto w-1/4 shadow-sm shadow-black"></div>
          <div class=" h-5 border-4 border-t-0 bg-blue-300 border-slate-200 mx-auto w-1/4 "></div>
        </div>
        <div class=" bg-blue-300 h-16 w-16 absolute flex rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 m-auto">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
          </svg>

        </div>
      </div>
      <div class=" w-full text-lg font-extrabold  text-center ">Unlimited Internet</div>
      </div>    
      
    </router-link>
    <router-link to="/PhoneCase" class=" w-32 flex flex-col cursor-pointer active:scale-95 hover:scale-105">
      <div class=" p-6 bg-gray-100 rounded-3xl border border-gray-500 shadow-lg shadow-black ">
        <div class=" bg-slate-200 w-full h-20 rounded-full relative p-2 mt-5 shadow shadow-black ">
        <div class=" left-0 -top-7 w-full absolute ">

          <div class=" h-2 rounded-xl border-4 bg-blue-200 border-slate-200 mx-auto w-2/5 shadow-sm shadow-black"></div>
          <div class=" h-5 border-4 border-t-0 border-b-0 bg-blue-200 border-slate-200 mx-auto w-1/4 shadow-sm shadow-black"></div>
          <div class=" h-5 border-4 border-t-0 bg-blue-300 border-slate-200 mx-auto w-1/4 "></div>
        </div>
        <div class=" bg-blue-300 h-16 w-16 absolute flex rounded-full ">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 m-auto">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
          </svg>


        </div>
      </div>
      <div class=" w-full text-lg font-extrabold  text-center">Phone Covers</div>
      </div>    
      
    </router-link>
    
    
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {
   
  }
}
</script>
