<template>
  <div class="absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>

    <div class=" orderPanel  absolute flex flex-col m-auto w-10/12 md:w-4/5 md:max-w-md rounded bg-slate-200">
      <div class="flex border-b border-blue-400 p-2">
        <div class="ml-auto font-bold text-xl">All Users {{ data.length }}</div>
        <div v-on:click="close()" class=" ml-auto w-8 h-8 bg-slate-100 border-slate-300 rounded-full flex active:scale-75 hover:scale-105">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" w-7 h-7 m-auto">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        </div>
      </div>
      
      
      <div class=" flex flex-col overflow-y-auto mt-2 w-full text-sm">
        <div v-for="(data,i) in data" :key="i" class=" w-full p-4">
          <div class="flex flex-col w-full h-full rounded bg-gray-100 p-4">
            
          <div class="mt-2 flex flex-row">
            <div class=" ">User Name: {{ data.username }}</div>
            <div v-if="data.is_admin" class=" font-medium ml-auto">Admin</div>            
          </div>

          <div class="mt-2 flex flex-row">
            <div class=" ">First Name: {{ data.first_name }}</div>                   
          </div>

          <div class="mt-2 flex flex-row">
            <div class=" ">Last Name: {{ data.last_name }}</div>                   
          </div>

          <div class="mt-2 flex flex-row">
            <div class=" ">Community: {{ data.community.community_name }}</div>                   
          </div>

          <div class="mt-2 flex flex-row">
            <div class=" ">Email: {{ data.email }}</div>                   
          </div>

          <div class="mt-2 flex flex-row">
            <div class=" ">Phone Number: {{ data.phone_number }}</div>                   
          </div>

          <div class="mt-2 flex flex-row">
            <button v-on:click="editUserCommunityButton(data)" class="  bg-white mt-3  text-gray-600 font-medium cursor-pointer shadow px-2 py-1 ml-auto"> Change Community </button>                 
          </div>


            
        </div>         
          
        </div>

      </div>
    
    </div>

  

    <div v-if="previewCase" class=" absolute top-0 z-50  w-full h-full flex items-center justify-center">
    
    <div class=" w-full h-full bg-gray-500 blur-lg opacity-70"></div>
    <div class=" absolute flex flex-col m-auto text-xl font-bold w-10/12 md:w-96 rounded bg-white p-6">
      
      
      <img v-if="ImageData" alt="logo" class="" :src=ImageData>

        <button
                class=" mt-4 self-center bg-gray-200 border-gray-300 border font-medium text-gray-600 cursor-pointer shadow py-2 px-4 mx-auto"
                @click="showPreview(false)"
              >
                Close
        </button>
        <!-- <button
                class=" mt-4 self-center bg-gray-200 border-gray-300 border font-medium text-gray-600 cursor-pointer shadow py-2 px-4 mx-auto"
                @click="ShowPayerMsgBox(false)"
              >
                Save Image
        </button> -->

      </div>
      
    </div>

    <setOrderStatus v-if="showSetOrderStatus"></setOrderStatus>

    <editUserCommunity v-if="showEditUserCommunity"></editUserCommunity>
  
  </div>
  
</template>

<script>
// @ is an alias to /src

import axios from "axios";
import { mapGetters, mapMutations,mapActions } from "vuex";
import SetOrderStatus from "@/views/admin/SetOrderStatusView.vue";
import EditUserCommunity from "@/views/admin/ChangeUserCommunity.vue";

export default {
  name: 'UsersPage',
  components: {
    setOrderStatus: SetOrderStatus,
    editUserCommunity: EditUserCommunity,
  },
  data () {
       return{
        details:'',
        amount:'',
        status:'',
        order_date:'',
        data:[],
        selectedUser:[],
        pageNumber: 1,
        PageSize: 100,
        previewCase: false,
        ImageData:null,
        selectedOrder:null,
        selectedOrderIndex:null,
        showSetOrderStatus: false,
        showEditUserCommunity: false,
        BackendUrl: "https://labsapi.packymstechnology.com"
       }
      },

      computed: {
      ...mapGetters(["GET_SCREENSHOT"]),

    },
  methods:{
    ...mapMutations([
        "REGENERATE_GEOMETRY", 
        "LOADED_STL",
        "SET_COLOR",
        "SET_SCREENSHOT_POSITION"      
      ]),
      ...mapActions(["REGENERATE_GEOMETRY","LOAD_STL","TAKE_SCREENSHOT"]),
    
    updateAndClose(valueData){
      this.data[this.selectedOrderIndex].order_status = valueData;
      this.showUpdateOrderStatus(false);
    },    
    close(){
      this.$parent.showAllUsers(false);
    },
    showPreview(show,img){
      if(show){
      //   this.SET_SCREENSHOT_POSITION()
      // this.$parent.showLoading(true)
      // this.TAKE_SCREENSHOT().then(() => {
      //       setTimeout(()=>{
      //         // this.LOADED_STL()
      //         this.ImageData = this.GET_SCREENSHOT
      //         this.previewCase = true
      //         this.$parent.showLoading(false)
      //       },1000)
      //     })
        this.previewCase = true
        this.ImageData = img
      }else{
        this.previewCase = false
      }
      
    },
    showPayment(amount, ref){
      this.$parent.showPayment(true, amount, ref);
    },
    async loadOrders(){
      this.$parent.showLoading(true)
      let dev = process.env.VUE_APP_DEVELOPMENT;    
    if (dev == "true") {
      console.log("is in dev mode")
      this.BackendUrl = "";
    }
         
      try {
        let result = await axios.get(this.BackendUrl + "/admin/all_users", {
            headers:{
            Authorization : `Bearer ${JSON.parse(localStorage.getItem('account')).access_token}`
          }
          });
          if(result.status == 200){
            // alert(" Order fetched")
            this.data = result.data
            // this.close();
  
          } else {
            alert("there was an error with placing Order")
          }
      } catch (error) {
        const code = error.response.status
        
          if(code == 401){
            this.close();
            this.$parent.setMessageBox("Please LogIn Again", "warning")
            this.$parent.showLogin(true);
          } else{
            this.$parent.setMessageBox("Something went wrong, Please check your internet connection or try again later", "error")
          }
      }
     
  
          
          this.$parent.showLoading(false)
    },

    GetColorFromDetails(details){
      let options = details.split(",");
      let color = options[1].trim();
      return color
    },

    SetPreview(details, path){
      let dev = process.env.VUE_APP_DEVELOPMENT;  
      if (dev == "true") {
      console.log("is in dev mode")
      this.BackendUrl = "http://localhost:8080";
      path = this.BackendUrl + path.substring(1)
    }
      let color = this.GetColorFromDetails(details)
      this.SET_COLOR(color);
      this.LOAD_STL(path).then(() => {
            setTimeout(()=>{
              // this.LOADED_STL()
              this.$parent.showLoading(false)
              this.close()
            },1000)
          // this.applyChanges();
          });
    },
    showLoading(show){
      this.$parent.showLoading(show)
    },
    showUpdateOrderStatus(show){
      this.showSetOrderStatus = show;
    },

    showEditUserCommunityPanel(show){
      this.showEditUserCommunity = show;
    },

    editUserCommunityButton(data){
      this.selectedUser = data;
      this.showEditUserCommunityPanel(true)
    },

    ChangeStatus(id, index){
      this.selectedOrder = id;
      this.selectedOrderIndex = index;
      this.showUpdateOrderStatus(true);
    },
    setMessageBox(message,type){
      this.$parent.setMessageBox(message, type)
    }
  },
  mounted(){
    let user = localStorage.getItem('account');
    if(user) {
      this.loadOrders()
    }
  }
  
}
</script>
<style scoped>
.orderPanel{
max-height: 85%;
}
</style>
